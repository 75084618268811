import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useContext, useEffect, useRef, useState } from "react";
import { depositMethods } from "../static";
import { Table } from "../components";
import {
  Content,
  Card,
  TitleText,
  OutlinedButton,
  OutlinedBox,
} from "../styles";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../firebase/firebase";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, onSnapshot, serverTimestamp, setDoc } from "firebase/firestore";
import { userContext } from "../context/userContext";
import DepositHistory from "../usertables/DepositHistory";
import ConnectWalletModal from "./ConnectWalletModal";
import DepositCoinbaseModal from "../modals/DepositCoinbaseModal";
import DepositMetamaskModal from "../modals/DepositMetamaskModal";
import DepositWalletConnectModal from "../modals/DepositWalletConnectModal";

const Deposit = () => {
  const [active, setActive] = useState("Bitcoin");
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const [coin, setCoin] = useState("BTC");
  const [amount, setAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const imageRef = useRef();
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const { accounts, currentPrices } = useContext(userContext);
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [connectWallet, setConnectWallet] = useState(false);
  const [depositCoinbase, setDepositCoinbase] = useState(false);
  const [depositMetamask, setDepositMetamask] = useState(false);
  const [depositWC, setDepositWC] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [isDeposit, setIsDeposit] = useState("");
  const { userData } = useContext(userContext);

  const [depositMethod, setDepositMethod] = useState("");

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setLiveAccounts([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (imageLink) {
      setIsUploading(false);
    }
  }, [imageLink]);

  const amountRef = useRef();

  function reset() {
    setImageName("");
    setImageLink("");
    setAmount("");

    if (amountRef) {
      amountRef.current.value = "";
    }
  }

  function handleDeposit(method) {
    setIsDeposit(method.name);

    setDepositMethod(method);

    setTimeout(() => {
      setDepositCoinbase(true);
      setIsDeposit("");
    }, [1000]);

    // switch (name) {
    //   case "Pay with Crypto":
    //     setTimeout(() => {
    //       setDepositCoinbase(true);
    //       setIsDeposit("");
    //     }, [1000]);
    //     break;
    //   case "Pay with Metamask":
    //     setTimeout(() => {
    //       setDepositMetamask(true);
    //       setIsDeposit("");
    //     }, [1000]);
    //     break;
    //   case "Pay with Wallet":
    //     setTimeout(() => {
    //       setDepositWC(true);
    //       setIsDeposit("");
    //     }, [1000]);
    //     break;
    //   default:
    //     break;
    // }

    // setSelectedMethod(name);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          {depositCoinbase && (
            <DepositCoinbaseModal
              open={{ depositCoinbase, setDepositCoinbase }}
              user={user}
              method={depositMethod}
            />
          )}

          {depositMetamask && (
            <DepositMetamaskModal
              open={{ depositMetamask, setDepositMetamask }}
              user={user}
            />
          )}
          {depositWC && (
            <DepositWalletConnectModal
              open={{ depositWC, setDepositWC }}
              user={user}
            />
          )}
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Deposit"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Deposit</TitleText>

              <ContentWrapper>
                <Card
                  className="card"
                  style={{ boxSizing: "border-box", height: "fit-content" }}
                >
                  <SubText className="top">Choose Method</SubText>
                  <div
                    className="contentBody"
                    style={{
                      display: "grid",
                      gap: "0.7rem",
                      height: "max-content",
                    }}
                  >
                    {depositMethods.map((method) => (
                      <div
                        onClick={() => {
                          handleDeposit(method);
                        }}
                        style={{
                          display:
                            mobile &&
                            method.name === "Pay with Metamask" &&
                            "none",
                        }}
                        className="methodBox"
                        key={method.name}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            placeContent:
                              // isDeposit === method.name
                              // ?
                              "center",
                            // : "flex-start",
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: "16px",
                          }}
                        >
                          {isDeposit === method.name ? (
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              width={24}
                              height={24}
                              style={{
                                display: "flex",
                                alignSelf: "center",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                gap: "0.7rem",
                              }}
                            >
                              <img
                                src={method.icon}
                                alt="logo"
                                style={{ zIndex: "10" }}
                              />
                              {mobile && method.name === "Pay with Wallet" && (
                                <img
                                  src="wallets/metamask.webp"
                                  alt="logo"
                                  style={{
                                    position: "absolute",
                                    left: "-15px",
                                  }}
                                />
                              )}
                              <p>{method.name}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
              </ContentWrapper>
              <DepositHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      background-color: #1f273a;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      .dropDownIcon {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .sel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .btn {
    border: 1px solid #1f273a;
    color: white;
  }

  .btn:hover {
    background-color: #1f273a;
    color: #119ffa;
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .proofContainer {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .chooseFileBox {
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      display: grid;
      grid-template-columns: 124px auto;
      align-items: center;

      button {
        background: #1f273a;
        border-radius: 4px;
        border: none;
        margin: 2px;
        cursor: pointer;
        height: 36px;

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding: 10px;
          color: #ffffff;
        }
      }

      span {
        display: flex;
        /* text-align: center; */
        min-width: 100%;
        /* place-content: center; */
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        color: #a3a3a3;
      }
    }
  }

  .activeBox {
    height: 100%;
  }

  .button {
    margin-top: 25px;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* padding-bottom: 60px; */
`;

const SubText = styled.p`
  font-weight: 600;
  color: #a3a3a3;
`;

const BodyText = styled.p`
  color: #c9c9c9;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const ContentWrapper = styled.div`
  display: flex;
  /* grid-template-columns: 360px auto; */
  /* grid-template-rows: auto auto; */
  box-sizing: border-box;
  /* gap: 1rem; */
  width: 100%;
  height: max-content;
  margin-top: 50px;
  padding-bottom: 60px;

  /* @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: 360px auto;
  } */

  /* @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
  } */

  .body {
    display: grid;
    gap: 1.7rem;
  }

  .contentBody {
    /* /* max-width: 360px; */
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    place-items: center;
    /* gap: 0.5rem; */
    padding: 2rem;
    box-sizing: border-box;
  }

  .methodBox {
    background-color: #161c2a;
    padding: 0.84rem;
    border-radius: 0.5rem;
    color: white;
    display: grid;
    gap: 1rem;
    text-align: left;
    cursor: pointer;
    width: 360px;

    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    img {
      width: 20px;
      height: auto;
      border-radius: 100%;
    }
  }
`;
const ContentContainer = styled.div`
  max-width: 360px;
  text-align: left;
  display: grid;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const SubmitContent = styled.div`
  border-radius: 0.5rem;
  background-color: #161c2a;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  place-content: center;
`;

export default Deposit;
