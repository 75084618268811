import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAW2vRoDmlRDXSs8Tchk8VIXPODI5INgJ8",
  authDomain: "crypto-market-analysis-20f5a.firebaseapp.com",
  projectId: "crypto-market-analysis-20f5a",
  storageBucket: "crypto-market-analysis-20f5a.appspot.com",
  messagingSenderId: "790107214710",
  appId: "1:790107214710:web:2ddb19642b932b3f1b2057",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
